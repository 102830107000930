import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'animate.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import BaiduMap from 'vue-baidu-map'
import axios from 'axios'
import './utils/request'
import VueLazyload from 'vue-lazyload'
let ip = window.location.origin
// axios.defaults.baseURL = ip
// axios.defaults.baseURL = 'http://localhost:8080'
Vue.prototype.$http = axios
Vue.use(BaiduMap, { ak: '03KozMVcg9POSDQ3nBmZQFqIzFweUwkU' })
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueLazyload);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require('./assets/error.png'),
  loading: require('./assets/lazy-loading.gif'),
  attempt: 1
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
router.beforeEach((to, from, next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
  window.scrollTo(0,0)
  
})