<template>
  <div class="container">
    <div class="header">
      <div class="nav" :class="active == true ? 'active' : ''">
        <div class="nav-bar">
          <div class="left">
            <img src="@/assets/logo.jpg" alt="" />
          </div>
          <div class="right">
            <ul>
              <li><router-link to="/">首页</router-link></li>
              <li><router-link to="/System">软件系统</router-link></li>
              <li><router-link to="/Gateway">硬件网关</router-link></li>
              <li><router-link to="/Case">案例类</router-link></li>
              <li><router-link to="/Knowledge">知识库</router-link></li>
              <li><router-link to="/Cooperation">合作与服务</router-link></li>
              <li><router-link to="/About">关于我们</router-link></li>
              <!-- <li><router-link to="/Experience">在线体验</router-link></li> -->
              <li>
                <a href="http://idms.hooloe.com/login" target="_blank"
                  >在线体验</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <router-view></router-view>
    <el-backtop></el-backtop>
    <div class="footer">
      <div class="to">
        <div class="log">
          <div class="logo">
            <div class="bootom-img">
              <img src="@/assets/logo.jpg" alt="" />
            </div>
            <div class="bottom-text">
              <span>河洛实业</span>
            </div>
          </div>
          <div>
            <div class="content">
              <p style="margin-top: 10px; color: #fff; font-size: 33px">
                上 海 市 高 新 技 术 企 业
              </p>
            </div>
          </div>
        </div>
        <div class="empty">

        </div>
      </div>
      <div class="total_top">
        <div class="left">
          <div class="content_left">
            <div class="call">
                <div class="right-top">
                    <h3 style="margin-bottom:0">上海河洛实业有限公司</h3>
                    <p>地址：上海市静安区江场西路180号7号楼9A05</p>
                    <p>电话：021-56428021</p>
                    <p>地址：021-56428022</p>
                    <h3 style="margin-bottom:0">江苏河洛电子科技有限公司</h3>
                    <p>地址：江苏张家港杨舍镇泗港路章卿村工业区</p>
                    <p>电话：052-58389920</p>
                </div>
            </div>
          </div>
          <div class="content_right">
            <div class="bottom_left" style="padding-left:80px">
              <p style="font-size:16px;text-align:left">洽谈代理业务，请拨打400电话或<br>河洛官网提交申请，切勿轻信他人</p>
              <h3 style="margin-bottom:0;padding-right:18%;text-align:left">服务热线：</h3>
              <h2 style="margin-top:0;font-weight:500;text-align:left">400-110-6808</h2>
              <p style="font-size:16px;text-align:left;margin-bottom:2px">网址：www.hooloe.com</p>
              <p style="margin-top:0px;font-size:16px;text-align:left">更多详情，请咨询当地经销商</p>
            </div>
            <div class="right" style="text-align:center">
              <img style="width:80%" src="@/assets/tell.png" alt="" />
              <p>公众号二维码</p>
            </div>
            
          </div>
        </div>
        <div class="bottom_right">
          <h3 style="color:#fff;margin-top:0">链接导航</h3>
          <ul>
            <li><router-link to="/">首页</router-link></li>
            <li><router-link to="/System">软件系统</router-link></li>
            <li><router-link to="/Gateway">硬件网关</router-link></li>
            <li><router-link to="/Case">案例类</router-link></li>
            <li><router-link to="/Knowledeg">知识库</router-link></li>
            <li><router-link to="/Cooperation">合作与服务</router-link></li>
            <li><router-link to="/About">关于我们</router-link></li>
            <li><a href="http://idms.hooloe.com/login" target="_blank">在线体验</a></li>
            <!-- <li><router-link to="/Experience">在线体验</router-link></li> -->
          </ul>
        </div>
      </div>
      <div class="total_bottom" style="margin:0 auto">
        <p style="margin-top:1%;font-size:13px">© 2011 - {{year}} Hooloe All Rights Reserved 上海河洛实业有限公司 版权所有</p>
        <a href="https://beian.miit.gov.cn/" target="_blank"><p class="target_gov" style="">沪ICP备19032067号-1</p></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      year:null
    };
  },
  created() {
    let date = new Date()
    const Year = date.getFullYear()
    this.year = Year
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      // console.log("滚动高度", window.pageYOffset);
      this.pos = window.pageYOffset;
      // console.log(this.pos);
      if (this.pos > 550) {
        this.active = true;
      } else {
        this.active = false;
      }
      if (this.pos > 700) {
        this.third_show = true;
      } else {
        this.third_show = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.target_gov{
  margin-top:3% !important;
  text-align:center;
  font-size:13px;
}
.target_gov:hover{
  color: rgba(255, 255, 255) !important;
}
.el-backtop:hover {
  background-color: #e9e9e9;
}
.el-backtop,
.el-calendar-table td.is-today {
  color: red;
}
.el-backtop {
  background: #333;
}
a {
  text-decoration: none;
  color: #333;
}
li {
  list-style-type: none;
  display: inline-block;
}
ul {
  padding: 0;
  margin: 0;
}
.active {
  box-shadow: 0 1px 3px 2px rgb(231 231 231);
  background-color: rgba(255, 255, 255, 0.9);
}
.container {
  width: 100%;
  margin: 0 auto;
.header {
    width: 100%;
    margin: 0 auto;
    position: fixed;
    top: 0;
    z-index: 999;
    .nav {
      width: 100%;
    }
    .nav-bar {
      display: flex;
      // width: 1300px;
      margin: 0 7%;
      align-items: center;
    }
    .left {
      width: 15%;
      text-align: right;
      img {
        width: 195px;
        height: 41px;
        margin-top: -8px;
      }
    }
    .right {
      width: 1100px;
      min-width: 1100px;
        // min-width: 85%;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        li {
          height: 58px;
          line-height: 65px;
          margin: 0 20px;
          text-align: center;
          vertical-align: baseline;
          a {
            padding: 15px 10px;
            color: #333;
            font-size: 14px;
          }
        }
        a:hover {
          border-bottom: 2px solid red;
          color: red;
        }
        .router-link-exact-active {
          border-bottom: 2px solid red;
          color: red;
        }
      }
    }
  }
  .footer {
    margin: -2% auto 0;
    background: #333;
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
    overflow-x: hidden;
    color: rgba(255, 255, 255, 0.7) !important;
    .to{
      display: flex;
      transform: scale(0.95);
      .log{
        width: 40%;
          display: flex;
          flex-direction: column;

        .logo{
          display: flex;
          align-items: center;
        }
      }
      .empty{
        width: 60%;
      }
      div {
        display: flex;
        align-content: center;
        .bootom-img {
          width: 244px;
          img {
            width: 240px;
            height: 45px;
          }
        }
        .bottom-text {
          span {
            display: inline-block;
            font-size: 2.1em;
            margin-bottom: -7px;
          }
        }
        p {
          margin-top: 20px;
          margin-bottom: 0;
          color: hsl(0, 0%, 100%, 0.9);
          font-size: 24px;
        }
      }
    }
    .total_top {
      display: flex;
      transform: scale(0.95);
      height: 100%;
      .left {
        width: 100%;
        display: flex;
        .content_left {
          width: 50%;
          margin-top: 1%;
          display: flex;
          flex-direction: column;
          align-items: center;
          .call{
            width: 95%;
          }
          .right-top{
            width: 83%;
            display: flex;
            flex-direction: column;
            h3{
              color: #fff;
              font-weight: 500;
            }
            p{
              margin: 3px;
              font-size: 16px;
              color: #fff;
            }
          }
          .content {
            .top {
              color: #fff;
              font-size: 14px;
              text-indent: 1.5rem;
              line-height: 1.2rem;
            }
          }
        }
        .content_right {
          width: 65%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: -30px;
          .bottom_left {
            width: 70%;
            height: 235px;
            p{
              font-size: 13px;
              color: #fff;
            }
            h2{
              color: #fff;
            }
            a {
              color: #fff;
              font-size: 20px;
            }
          }
          .bottom_right {
            display: flex;
            flex-direction: column;
            margin-left: 10%;
          }
          h3 {
            color: #fff;
            margin-top: 5px;
          }
          
        }
      }
      .bottom_right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 30%;
            h2{
              color: #fff;
            }
            ul {
            display: flex;
            flex-direction: column;
            li {
              text-align: left;
              margin: 5% 0;
              font-size: 16px;
              a {
                color: #fff;
              }
              a:hover {
                color: red;
              }
            }
          }
          }
      .right {
        width: 35%;
        text-align: left;
        color: #fff;
        img {
          width: 70%;
        }
      }
    }
    .total_bottom {
      margin: 5% auto 0;
      p {
        margin: 19% auto 0%;
        font-size: 12px;
        color: hsla(0, 0%, 100%, 0.7);
      }
    }
  }
  .map {
    height: 300px;
    width: 100%;
    margin: 0 auto;
  }
}
</style>