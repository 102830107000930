import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    component: Home,
    children:[
      {
        path: '/',
        name: 'Index',
        component: () => import(/* webpackChunkName: "about" */ '../views/Index.vue'),
        meta:{title:'河洛实业'}
      },
      {
        path: '/System',
        name: 'System',
        component: () => import(/* webpackChunkName: "about" */ '../page/System.vue'),
        meta:{title:'软件系统'}
      },
      {
        path: '/Gateway',
        name: 'Gateway',
        component: () => import(/* webpackChunkName: "about" */ '../page/Gateway.vue'),
        meta:{title:'硬件网关'}
      },
      {
        path: '/Case',
        name: 'Case',
        component: () => import(/* webpackChunkName: "about" */ '../page/Case.vue'),
        meta:{title:'案例类'}
      },
      {
        path: '/About',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../page/About.vue'),
        meta:{title:'关于我们'}
      },
      {
        path: '/Experience',
        name: 'Experience',
        component: () => import(/* webpackChunkName: "about" */ '../page/Experience.vue')
      },
      {
        path: '/Cooperation',
        name: 'Cooperation',
        component: () => import(/* webpackChunkName: "about" */ '../page/Cooperation.vue'),
        meta:{title:'合作与服务'}

      },
      {
        path: '/Knowledge',
        name: 'Knowledge',
        component: () => import(/* webpackChunkName: "about" */ '../page/Knowledge.vue'),
        meta:{title:'知识库'}
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/views/notfound.vue')//404界面
  },
  {
    path: '*', 
    redirect: '/404'
  }
  
]

const router = new VueRouter({
  mode:'history',
  routes
})

export default router
